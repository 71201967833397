/* eslint-disable @next/next/no-page-custom-font */
import Head from 'next/head';
import Router, { NextRouter, useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import pageLoad from '../HOC/pageLoad';
import BaseLayout from '../Layout/BaseLayout';
import { SnackbarProvider } from '../contexts/SnackbarContext';
import '../styles/globals.css';
import '../styles/globals.scss';
import '../styles/nprogress.css';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../styles/slick.module.scss';

import axios from 'axios';
import Script from 'next/script';
import Cookies from 'universal-cookie';
import { apiConstants } from '../constants/apiConstants';
import { gtmConstants } from '../constants/gtmConstants';
import {
  NSURE_PRODUCTION_APP_ID,
  NSURE_SANDBOX_APP_ID,
} from '../constants/nsure';
import { AnalyticsProvider } from '../contexts/AnalyticsProvider';
import { ESimProvider } from '../contexts/ESimContext';
import { LocalizationProvider } from '../contexts/LocalizationContext';
import { NewsletterProvider } from '../contexts/NewsletterContext';
import { NotifyMeProductsProvider } from '../contexts/NotifyMeProductsContext';
import { PageLoaderProvider } from '../contexts/PageLoaderContext';
import { AppThemeProvider } from '../contexts/ThemeContext';
import { TrendingProductsProvider } from '../contexts/TrendingProductsContext';
import { useStore } from '../redux/newStore';
import { AuthProvider } from '../user/context/AuthContext';
import { setAcquisitionChannel } from '../utils/acquisitionChannel';
import { getPageData, saveEventV3 } from '../utils/eventTracking';
import { deleteLocalStorage } from '../utils/localStorageUtils';
const cookies = new Cookies();

// if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

function MyApp({ Component, pageProps }: any) {
  const reduxStore = useStore(pageProps.reduxInitialState);

  Router.events.on('routeChangeStart', (url) => {
    NProgress.start();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });

  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });

  const router = useRouter();
  const { locale } = router;

  const [historyState, setHistoryState] = useState<NextRouter[]>([]);

  // for affiliate tracking start
  useEffect(() => {
    if (
      router.query.utm_source &&
      (router.query.utm_source === 'admitad' || // admitad
        router.query.utm_source === 'awin' || // awin
        // router.query.utm_source === 'Taboola' || // taboola
        // router.query.utm_source === 'cj' || // cj
        router.query.utm_source === 'facebook-ads' || // facebook-ads
        router.query.utm_source === 'driffleaff' || // postAffiliatePro
        router.query.utm_source === 'google-shopping' || // google shopping
        router.query.utm_source === 'facebook-shopping' || // facebook shopping
        router.query.utm_source === 'snapchat-shopping') // snapchat shopping
    ) {
      deleteLocalStorage('dd_cookie');
      cookies.remove('dd_cookie');
    }
  }, [router.query]);
  // for affiliate tracking end

  // set drif_acq cookie
  useEffect(() => {
    if (router.isReady) {
      let utmCookie;
      let campaignCookie;

      // for google shopping
      if (router.query.srsltid && typeof router.query.srsltid === 'string') {
        utmCookie = 'google-shopping';
      }

      // for facebook shopping
      if (router.query.fbclid && typeof router.query.fbclid === 'string') {
        utmCookie = 'facebook-shopping';
      }

      // for snapchat shopping
      if (router.query.ScCid && typeof router.query.ScCid === 'string') {
        utmCookie = 'snapchat-shopping';
      }

      // for setting utm_source
      if (
        router.query.utm_source &&
        typeof router.query.utm_source === 'string'
      ) {
        utmCookie = router.query.utm_source;

        // for CJ Event ID
        // if (router.query.utm_source === 'cj' && router.query.cjevent) {
        //   axios
        //     .get(
        //       apiConstants.frontend +
        //         '/api/cookie/cj?cjevent=' +
        //         router.query.cjevent
        //     )
        //     .then(() => {})
        //     .catch(() => {});
        // }
      }

      // for setting utm_campaign
      if (
        router.query.utm_campaign &&
        typeof router.query.utm_campaign === 'string'
      ) {
        campaignCookie = router.query.utm_campaign;
      }

      // for postAffiliatePro refid is set to drif_acq_cam
      if (
        router.query.refid &&
        typeof router.query.refid === 'string' &&
        router.query.utm_source === 'driffleaff'
      ) {
        campaignCookie = router.query.refid;
      }

      let uri = apiConstants.frontend + '/api/cookie?';

      if (utmCookie || campaignCookie) {
        if (utmCookie) {
          uri += 'source=' + utmCookie + '&';
        }

        if (campaignCookie) {
          uri += 'campaign=' + campaignCookie;
        }
        axios
          .get(uri)
          .then(() => {})
          .catch(() => {});
      }
    }
  }, [router]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const pageData = getPageData(router);
    saveEventV3({
      category: pageData.pageName,
      action: 'page_view',
      label: pageData.pageLabel,
      properties: '',
      value: [pageData.pageValue],
      jsonData: {
        query: pageData.query,
      },
      from: historyState[historyState.length - 1] || '',
    });

    setHistoryState([...historyState, router]);
  }, [JSON.stringify(router)]);

  const acqRef = useRef<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && router.isReady && !acqRef.current) {
      setAcquisitionChannel(router);
      acqRef.current = true;
    }
  }, [JSON.stringify(router)]);

  const pwaInstallationEvent = useCallback(() => {
    saveEventV3({
      category: 'pwa',
      action: 'click',
      label: 'pwa_installed',
      properties: '',
      value: [],
      from: router,
    });
  }, [router]);

  useEffect(() => {
    window.addEventListener('appinstalled', pwaInstallationEvent);
    return () => {
      window.removeEventListener('appinstalled', pwaInstallationEvent);
    };
  }, [pwaInstallationEvent]);

  useEffect(() => {
    try {
      caches
        .delete('cross-origin-image-cache')
        .then(function (isDeleted) {
          if (isDeleted) {
            console.log('Cache successfully deleted!');
          }
        })
        .catch((e) => {});
    } catch (err: any) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Script
        strategy="afterInteractive"
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `(
                function(w,d,s,l,i){
                  w[l]=w[l]||[];
                  w[l].push({
                    'gtm.start':
                    new Date().getTime(),
                    event:'gtm.js'
                  });
                  var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl=l!='dataLayer'?'&l='+l:'';
                  j.async=true;
                  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                  f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${gtmConstants.gtmId}');`,
        }}
      />
      {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
        <>
          <Script
            id="nsure-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `try{
                          window.nSureAsyncInit = function(deviceId) {
                            window.nSureSDK.init('${NSURE_PRODUCTION_APP_ID}');
                          }
                      }catch(e){}`,
            }}
          />
          <Script
            src="https://sdk.nsureapi.com/sdk.js"
            strategy="afterInteractive"
          />
        </>
      ) : (
        <>
          <Script
            id="nsure-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `try{
                          window.nSureAsyncInit = function(deviceId) {
                            window.nSureSDK.init('${NSURE_SANDBOX_APP_ID}');
                          }
                      }catch(e){}`,
            }}
          />
          <Script
            src="https://sdk.nsureapi.com/sdk-sandbox.js"
            strategy="afterInteractive"
          />
        </>
      )}

      {(process.env.NEXT_PUBLIC_APP_ENV === 'production' ||
        process.env.NEXT_PUBLIC_APP_ENV === 'staging') && (
        // {
        <Script
          id="service-worker"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          if ("serviceWorker" in navigator) {
            // Use the window load event to keep the page load performant
            try{
                window.addEventListener("load", () => {
                navigator.serviceWorker.register("/sw.js").then(function(registration) {
                  //  console.log("Service worker registered: ", registration);
                }).catch(()=>{})
              })
            }catch(e){
            }
          }
          `,
          }}
        />
      )}
      {/* } */}

      <Script
        src="https://accounts.google.com/gsi/client"
        strategy="afterInteractive"
      />

      {/* <!-- Start of Jewel ML Embed Code --> */}
      <Script
        strategy="afterInteractive"
        id="jewel-ml-render"
        dangerouslySetInnerHTML={{
          __html: `
            var d = document || window.document; var s = 'script'; var s0 = d.getElementsByTagName(s)[ 0 ]; var ps = [
              '//rerender.jewelml.io/r/d/67052b4875c930a15d8e2867/r.js' ];
            ps.forEach(function (p) { var j = d.createElement(s); j.defer = true; j.type = 'text/javascript'; j.src = p; s0.parentNode.insertBefore(j, s0); });
          `,
        }}
      />
      {/* <!-- End of Jewel ML Embed Code --> */}

      {/* <!-- Start of Jewel ML Embed Code --> */}
      <Script
        id="jewel-ml-event"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            var d = document || window.document; var s = 'script'; var s0 = d.getElementsByTagName(s)[ 0 ]; var ps = [
            '//ga4events.jewelml.io/v/67052b4875c930a15d8e2867/js' ];
            ps.forEach(function (p) { var j = d.createElement(s); j.async = true; j.type = 'text/javascript'; j.src = p; s0.parentNode.insertBefore(j, s0); });
          `,
        }}
      />
      {/* <!-- End of Jewel ML Embed Code --> */}

      {/* <!-- Facebook Pixel Code --> */}
      {/* {(cookies.get('drif_acq') === 'facebook-ads' ||
          router.query.utm_source === 'facebook-ads') && ( */}
      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '211906725136325');
            fbq('track', 'PageView');
            `,
        }}
      />
      {/* )} */}
      {/* {(cookies.get('drif_acq') === 'facebook-ads' ||
          router.query.utm_source === 'facebook-ads') && ( */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <img 
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=211906725136325&ev=PageView&noscript=1"
            />
          `,
        }}
      />
      {/* )} */}
      {/* <!-- End Facebook Pixel Code --> */}

      {/* <!-- Snap Pixel Code --> */}
      <Script
        id="snap-pixel"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
             try {
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s="script";r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                'https://sc-static.net/scevent.min.js');
    
                snaptr('init', '947e2daa-f0ff-4f0c-94e8-261b514a5c8c');
    
                snaptr('track', 'PAGE_VIEW');
             } catch (error) {
                console.log(error);
             }
            `,
        }}
      />
      {/* <!-- End Snap Pixel Code --> */}

      {/* <Script src="./js/admitAd.js" /> */}

      {/* {(cookies.get('drif_acq') === 'driffleaff' ||
        router.query.utm_source === 'driffleaff') && ( */}
      {/* <Script
        type="text/javascript"
        id="pap_x2s6df8d"
        src="https://driffle.postaffiliatepro.com/scripts/gqwn1j4d2"
      /> */}
      {/* )} */}

      {/* {(cookies.get('drif_acq') === 'driffleaff' ||
        router.query.utm_source === 'driffleaff') && ( */}
      {/* <Script
        type="text/javascript"
        id="pap_tracker"
        dangerouslySetInnerHTML={{
          __html: `
            setTimeout(function () {
              try {
                if (window.PostAffTracker) {
                  PostAffTracker.setAccountId('default1');
                  PostAffTracker.track();
                } else {
                  //console.log('else');
                }
              } catch (err) {
                console.log(err);
              }
            }, 3000);
            `,
        }}
      /> */}
      {/* )} */}

      <Head>
        <title>Driffle</title>
        {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
          <>
            <link rel="dns-prefetch" href="https://api.driffle.com" />
            <link rel="preconnect" href="https://api.driffle.com" />
          </>
        ) : (
          <>
            <link rel="dns-prefetch" href="https://api.69gamers.com" />
            <link rel="preconnect" href="https://api.69gamers.com" />
          </>
        )}

        <link rel="preconnect" href="https://assets.driffle.com" />
        <link rel="preconnect" href="https://static.driffle.com" />

        <link rel="dns-prefetch" href="https://assets.driffle.com" />
        <link rel="dns-prefetch" href="https://static.driffle.com" />

        <meta name="google-adsense-account" content="ca-pub-2614172121091543" />

        {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
          <>
            <link
              rel="preload"
              href="https://assets.driffle.com/fonts/Onest/static/Onest-Medium.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="https://assets.driffle.com/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="https://assets.driffle.com/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
          </>
        ) : (
          <>
            <link
              rel="preload"
              href="/fonts/Onest/static/Onest-Medium.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
          </>
        )}

        {/* {languageList.map((val: any, index: number) => (
          <link
            rel="alternate"
            type="text/html"
            hrefLang={val.locale}
            title={val.name}
            href={
              apiConstants.frontend +
              (val.locale === 'en' ? '' : `/${val.locale}`) +
              router.asPath
            }
            key={index}
          />
        ))} */}

        <link rel="shortcut icon" href="/site-assets/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/site-assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/site-assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/site-assets/favicon-16x16.png"
        />
        <link rel="preload" as="image" href="/logo/driffle-logo-white.svg" />

        {pageProps.reduxInitialState?.user?.subscription === 'active' && (
          <link
            rel="preload"
            as="image"
            href="/logo/driffle-plus-dark-logo.svg"
          />
        )}

        <link rel="manifest" href="/manifest.json"></link>

        <script
          defer
          type="text/javascript"
          src="https://app.termly.io/resource-blocker/e8348f34-2290-4567-bf08-353586ec9bc0?autoBlock=on"
        />

        <script
          id="json-ld-product"
          defer
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@graph': [
                {
                  '@context': 'https://schema.org/',
                  '@type': 'Organization',
                  name: 'Driffle Inc',
                  url: 'https://driffle.com/',
                  logo: 'https://driffle.com/logo/driffle-logo-white.svg',
                  description:
                    'Driffle is a digital goods marketplace specializing in digital gaming products, including games, gift cards, DLCs, and more. We offer a convenient and diverse selection, from the newest releases to timeless classics, all in one place.',
                  brand: {
                    '@type': 'Brand',
                    name: 'Driffle',
                  },
                  contactPoint: {
                    '@type': 'contactPoint',
                    contactType: 'customer service',
                    url: 'https://driffle.com/support',
                    email: 'support@driffle.com',
                    availableLanguage: [
                      'English',
                      'Spanish',
                      'German',
                      'Italian',
                      'Dutch',
                      'French',
                      'Hungarian',
                      'Polish',
                      'Danish',
                      'Portuguese',
                      'Finnish',
                      'Swedish',
                      'Brazilian',
                      'Indonesian',
                      'Thai',
                      'Vietnamese',
                      'Czech',
                      'Greek',
                      'Filipino',
                      'Romanian',
                      'Russian',
                      'Turkish',
                      'Lithuanian',
                      'Simplified Chinese',
                    ],
                  },
                  sameAs: [
                    'https://www.linkedin.com/company/driffle/',
                    'https://www.facebook.com/drifflecom/',
                    'https://www.instagram.com/drifflecom/',
                    'https://discord.gg/MryXrnwZvz',
                    'https://www.youtube.com/@drifflecom',
                  ],
                },
              ],
            }),
          }}
        />

        <script
          src="https://www.artfut.com/static/tagtag.min.js?campaign_code=a19541ff56"
          defer
          //@ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          onerror='var self = this;window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers=ADMITAD.Helpers||{},ADMITAD.Helpers.generateDomains=function(){for(var e=new Date,n=Math.floor(new Date(2020,e.getMonth(),e.getDate()).setUTCHours(0,0,0,0)/1e3),t=parseInt(1e12*(Math.sin(n)+1)).toString(30),i=["de"],o=[],a=0;a<i.length;++a)o.push({domain:t+"."+i[a],name:t});return o},ADMITAD.Helpers.findTodaysDomain=function(e){function n(){var o=new XMLHttpRequest,a=i[t].domain,D="https://"+a+"/";o.open("HEAD",D,!0),o.onload=function(){setTimeout(e,0,i[t])},o.onerror=function(){++t<i.length?setTimeout(n,0):setTimeout(e,0,void 0)},o.send()}var t=0,i=ADMITAD.Helpers.generateDomains();n()},window.ADMITAD=window.ADMITAD||{},ADMITAD.Helpers.findTodaysDomain(function(e){if(window.ADMITAD.dynamic=e,window.ADMITAD.dynamic){var n=function(){return function(){return self.src?self:""}}(),t=n(),i=(/campaign_code=([^&]+)/.exec(t.src)||[])[1]||"";t.parentNode.removeChild(t);var o=document.getElementsByTagName("head")[0],a=document.createElement("script");a.src="https://www."+window.ADMITAD.dynamic.domain+"/static/"+window.ADMITAD.dynamic.name.slice(1)+window.ADMITAD.dynamic.name.slice(0,1)+".min.js?campaign_code="+i,o.appendChild(a)}});'
        ></script>

        <script
          id="ad"
          defer
          dangerouslySetInnerHTML={{
            __html: `
            try{
              // name of the cookie that stores the source
                  // change if you have another name
                
                  var cookie_name = 'deduplication_cookie';
                  // cookie lifetime
                  var days_to_store = 90;
                  // expected deduplication_cookie value for Admitad
                  var deduplication_cookie_value = 'admitad';
                  // name of GET parameter for deduplication
                  // change if you have another name
                  var channel_name = 'utm_source';
                  // a function to get the source from the GET parameter
                  getSourceParamFromUri = function () {
                    var pattern = channel_name + '=([^&]+)';
                    var re = new RegExp(pattern);
                    return (re.exec(document.location.search) || [])[1] || '';
                  };

                  // a function to get the source from the cookie named cookie_name
                  getSourceCookie = function () {
                    var matches = document.cookie.match(new RegExp(
                      '(?:^|; )' + cookie_name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
                    ));
                    return matches ? decodeURIComponent(matches[1]) : undefined;
                  };
                  // a function to set the source in the cookie named cookie_name
                  setSourceCookie = function () {
                    var param = getSourceParamFromUri();
                    var params = (new URL(document.location)).searchParams;
                    if (!params.get(channel_name) && params.get('gclid')) { param = 'advAutoMarkup' }
                    else if (!param) { return; }
                    var period = days_to_store * 60 * 60 * 24 * 1000; // in seconds
                    var expiresDate = new Date((period) + +new Date);
                    var cookieString = cookie_name + '=' + param + '; path=/; expires=' + expiresDate.toGMTString();
                    document.cookie = cookieString;
                    document.cookie = cookieString + '; domain=.' + location.host;
                  };
                  // set cookie
                  setSourceCookie();
                }catch(e)
                {
                  console.log(e)
                }
          `,
          }}
        />

        {/* <!-- Taboola Pixel Code --> */}
        {/* <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'page_view', id: 1564834});
            !function (t, f, a, x) {
                  if (!document.getElementById(x)) {
                      t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                  }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/1564834/tfa.js',
            'tb_tfa_script');
          `,
          }}
        ></script> */}
        {/* <!-- End of Taboola Pixel Code --> */}

        {/* mywot.com site claim tag start*/}
        {/* <meta name="wot-verification" content="e3311bc6dbb5357f2b0c" /> */}
        {/* mywot.com site claim tag end*/}

        {/* TicTok Pixel Code */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            
              ttq.load('CNT9MHBC77UBM1BSGRP0');
              ttq.page();
            }(window, document, 'ttq');
            `,
          }}
        /> */}
        {/* <!-- End TikTok Pixel Code --> */}
      </Head>

      <LocalizationProvider
        locale={(pageProps as { locale?: string })?.locale || locale || 'en'}
        localeData={(pageProps as { localeData?: any })?.localeData}
      >
        <Provider store={reduxStore}>
          <AppThemeProvider>
            <SnackbarProvider>
              <PageLoaderProvider>
                <ESimProvider>
                  <TrendingProductsProvider>
                    <AnalyticsProvider>
                      <BaseLayout>
                        <AuthProvider>
                          <NotifyMeProductsProvider>
                            <NewsletterProvider>
                              <Component {...pageProps} />
                            </NewsletterProvider>
                          </NotifyMeProductsProvider>
                        </AuthProvider>
                      </BaseLayout>
                    </AnalyticsProvider>
                  </TrendingProductsProvider>
                </ESimProvider>
              </PageLoaderProvider>
            </SnackbarProvider>
          </AppThemeProvider>
        </Provider>
      </LocalizationProvider>

      {/* <!-- BEGIN AWIN MASTER TAG - DO NOT MODIFY --> */}
      <Script
        src="https://www.dwin1.com/52015.js"
        type="text/javascript"
        strategy="afterInteractive"
      />
      {/* <!-- END AWIN MASTER TAG --> */}

      {/* <!-- BEGIN CJ TRACKING CODE - DO NOT MODIFY --> */}
      {/* <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function(a,b,c,d){
            a='//www.mczbf.com/tags/598241255716/tag.js';
            b=document;c='script';d=b.createElement(c);d.src=a;
            d.type='text/java'+c;d.async=true;
            d.id='cjapitag';
            a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
            })();
          `,
        }}
      ></S> */}
      {/* <!-- END CJ TRACKING CODE --> */}
    </>
  );
}
export default pageLoad(MyApp);
